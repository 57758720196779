import "./creditcard.css";
import interest_bulletin from "../../assets/docx/interest_bulletin.docx";
import deposit_general_terms_and_conditions from "../../assets/docx/Deposit.doc";
import React from "react";
import { useState, useEffect } from "react";

function CreditCard({ lang }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [potr_apply, set_potr_apply] = useState(false);

  const [active, setActive] = useState({
    status: false,
    element: null,
    index: null,
  });
  let c;

  function caret(e) {
    if (!active.status) {
      c = {
        status: true,
        element: document.getElementById("main-caret"),
        index: 1,
      };
    } else {
      c = active;
    }
    const main = document.getElementById("main-caret");
    if (
      e.target.id === "main-caret" &&
      !e.target.children[2].classList.contains("none")
    ) {
      setActive({
        status: true,
        element: e.target,
        index: e.target.dataset.index,
      });
    } else {
      c.element.children[2].classList.add("none");
      e.target.children[2].classList.remove("none");
      setActive({
        status: true,
        element: e.target,
        index: e.target.dataset.index,
      });
    }
  }

  return (
    <>
      <div id="creditca-banner"></div>
      <div className="mini-menu">
        <ul className="mini-links">
          <li data-index="1" id="main-caret" onClick={caret}>
            <i className="fa-solid fa-circle-info mini-icons"></i>
            <span className="mini-link-title">
              {lang === "EN"
                ? "Information"
                : lang === "BG"
                ? "Информация"
                : "Information"}
            </span>
            <i data-b="1" className="fa-solid fa-caret-up display"></i>
          </li>
          <li data-index="2" onClick={caret}>
            <i className="fa-solid fa-sliders mini-icons"></i>
            <span className="mini-link-title">
              {lang === "EN"
                ? "Parameters"
                : lang === "BG"
                ? "Параметри"
                : "Parameter"}
            </span>
            <i data-b="0" className="fa-solid fa-caret-up none display"></i>
          </li>

          <li data-index="4" onClick={caret}>
            <i className="fa-solid fa-file mini-icons"></i>
            <span className="mini-link-title">
              {lang === "EN"
                ? "Documents"
                : lang === "BG"
                ? "Документи"
                : "Dokumente"}
            </span>
            <i data-b="3" className="fa-solid fa-caret-up none display"></i>
          </li>
        </ul>
      </div>
      {Number(active.index) === 1 || active.index === null ? (
        <div className="potr-section">
          <h1 style={{ textAlign: "center" }}>
            {lang === "EN"
              ? "Credit card"
              : lang === "BG"
              ? "Кредитна карта"
              : "Kreditkarte"}
          </h1>
          <div>
            <div id="potr-info">
              {lang === "EN" ? (
                <p
                  style={{
                    fontSize: "2rem",
                  }}
                >
                  The credit card is a type of means of payment for the purchase
                  of goods or services, and the amount for this financial action
                  is based on the credit limit granted by the bank. The
                  individual uses her money against the card's limit, after
                  which she must return the amount used. This banking service is
                  characterized by the grace period, which consists in the fact
                  that if the client does not pay his obligation within this
                  period, then he owes interest to the bank for the money he
                  used, and this is considered the price of this banking
                  service.
                </p>
              ) : lang === "BG" ? (
                <p
                  style={{
                    fontSize: "2rem",
                  }}
                >
                  Kредитната карта е вид разплащателно средство за покупка на
                  стоки или услуги, като сумата за това финансово действие е на
                  база отпуснатия кредитен лимит от банката. Физическото лице
                  използва нейните пари спрямо лимита на картата, след което то
                  трябва да и върне използваната сума. При тази банкова услуга е
                  характерен гратисният период, който се състои в това, че ако
                  клиентът не изплати задължението си в този срок, то тогава той
                  дължи лихва за използваните от него парични средства на
                  банката, като това се счита за цената на тази банкова услуга.
                </p>
              ) : (
                <p
                  style={{
                    fontSize: "2rem",
                  }}
                >
                  Die Kreditkarte ist ein Zahlungsmittel für den Kauf von Waren
                  oder Dienstleistungen, und der Betrag für diese finanzielle
                  Aktion richtet sich nach dem von der Bank gewährten
                  Kreditlimit. Die Person verwendet ihr Geld gegen das Limit der
                  Karte, danach muss sie den verwendeten Betrag zurückerstatten.
                  Diese Bankdienstleistung ist durch die Nachfrist
                  gekennzeichnet, die darin besteht, dass der Kunde, wenn er
                  seine Verbindlichkeit nicht innerhalb dieser Frist begleicht,
                  der Bank Zinsen für das von ihm verwendete Geld schuldet, und
                  dies gilt als Preis dieser Bank Dienst.
                </p>
              )}
            </div>
          </div>
        </div>
      ) : null}
      {Number(active.index) === 2 ? (
        <div className="potr-section">
          <h1 style={{ textAlign: "center" }}>
            {lang === "EN"
              ? "Parameters"
              : lang === "BG"
              ? "Параметри"
              : "Parameter"}
          </h1>
          <div id="par-potr">
            <div>
              <i className="fa-regular fa-clock"></i>
              <div>
                <h1>
                  {lang === "EN"
                    ? "Grace period"
                    : lang === "BG"
                    ? "Гратисен период "
                    : "Gnadenfrist"}
                </h1>
                <p>
                  {lang === "EN"
                    ? "45 days"
                    : lang === "BG"
                    ? "45 дни"
                    : "45 Tage"}
                </p>
              </div>
            </div>
            <div>
              <i className="fa-solid fa-percent"></i>
              <div>
                <h1>
                  {lang === "EN"
                    ? "Interest"
                    : lang === "BG"
                    ? "Лихва"
                    : "Zinsen"}
                </h1>
                <p>
                  {lang === "EN"
                    ? "17.99%"
                    : lang === "BG"
                    ? "17.99%"
                    : "17.99%"}
                </p>
              </div>
            </div>

            <div>
              <i className="fa-regular fa-credit-card"></i>
              <div>
                <h1>
                  {lang === "EN"
                    ? "Card brand"
                    : lang === "BG"
                    ? "Марка на картата"
                    : "Marke der Karte"}
                </h1>
                <p>
                  {lang === "EN"
                    ? "Mastercard / Visa"
                    : lang === "BG"
                    ? "Mastercard / Visa"
                    : "Mastercard / Visa"}
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {Number(active.index) === 4 ? (
        <div className="potr-section">
          <p
            style={{
              borderRadius: "15px",
              border: "4px #16c680 solid",
              padding: "2px 5px",
              fontSize: "1.5rem",
              fontWeight: "bold",
              marginBottom: "1rem",
              width: "100%",
            }}
          >
            <a
              style={{
                color: "#16c680",
              }}
              href={interest_bulletin}
              download
            >
              Interest Bulletin - Download
            </a>
          </p>
        </div>
      ) : null}

      <div id="take">
        <div
          onClick={() => {
            const tkDc = document.getElementById("take");

            try {
              tkDc.children[0].classList.remove("take-hover");
              tkDc.children[0].classList.add("take-flex");

              set_potr_apply(true);
            } catch {}
          }}
          className="take-hover"
        >
          {potr_apply === true ? (
            <>
              <form>
                <input
                  type="email"
                  required={true}
                  maxLength={50}
                  placeholder="Enter your email..."
                />
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    const tkDc = document.getElementById("take");

                    tkDc.children[0].classList.remove("take-flex");

                    set_potr_apply("next");
                  }}
                  type="submit"
                  style={{ background: "green" }}
                >
                  <i className="fa-solid fa-check"></i>
                </button>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    const tkDc = document.getElementById("take");
                    tkDc.children[0].classList.add("take-hover");
                    tkDc.children[0].classList.remove("take-flex");

                    set_potr_apply(false);
                  }}
                  type="button"
                  style={{ background: "red", cursor: "pointer" }}
                >
                  <i className="fa-solid fa-xmark"></i>
                </button>
              </form>
            </>
          ) : potr_apply === "next" ? (
            <h2
              onClick={(e) => {
                e.stopPropagation();
              }}
              style={{ color: "white" }}
            >
              {lang === "EN"
                ? `Request sent!`
                : lang === "BG"
                ? `Заявлението изпратено!`
                : `Anfrage gesendet!`}
            </h2>
          ) : (
            <button>
              {lang === "EN"
                ? `Apply now!`
                : lang === "BG"
                ? `Заявете сега!`
                : `Jetzt Auftragen!`}
            </button>
          )}
        </div>
      </div>
    </>
  );
}

export default CreditCard;
