import "./potrCredit.css";
import React from "react";
import interest_bulletin from "../../assets/docx/interest_bulletin.docx";
// import tresor_general_terms_and_conditions from "../../assets/docx/tresor.doc";
import { useState, useEffect } from "react";

function PotrCredit({ lang }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [value, setValue] = useState(1000);
  const [months, setMonths] = useState(3);
  const [potr_apply, set_potr_apply] = useState(false);

  function creditCalc(value, months) {
    let interest = 6.5;
    const percent = interest / 100 / 12;
    let pow = percent + 1;
    pow = Math.pow(pow, -months);
    const finalValue = (percent / (1 - pow)) * Number(value);
    const wholeValue = months * finalValue;
    document.getElementById("wh-val").innerText = wholeValue.toFixed(2);
    document.getElementById("mth-pay").innerText = finalValue.toFixed(2);
    document.getElementById("mth-pr").innerText = interest;
  }
  useEffect(() => {
    creditCalc(value, months);
  });

  const [active, setActive] = useState({
    status: false,
    element: null,
    index: null,
  });
  let c;

  function caret(e) {
    if (!active.status) {
      c = {
        status: true,
        element: document.getElementById("main-caret"),
        index: 1,
      };
    } else {
      c = active;
    }
    const main = document.getElementById("main-caret");
    if (
      e.target.id === "main-caret" &&
      !e.target.children[2].classList.contains("none")
    ) {
      setActive({
        status: true,
        element: e.target,
        index: e.target.dataset.index,
      });
    } else {
      c.element.children[2].classList.add("none");
      e.target.children[2].classList.remove("none");
      setActive({
        status: true,
        element: e.target,
        index: e.target.dataset.index,
      });
    }
  }

  return (
    <>
      <div id="credit-banner"></div>
      <div className="mini-menu">
        <ul className="mini-links">
          <li data-index="1" id="main-caret" onClick={caret}>
            <i className="fa-solid fa-circle-info mini-icons"></i>
            <span className="mini-link-title">
              {lang === "EN"
                ? "Information"
                : lang === "BG"
                ? "Информация"
                : "Information"}
            </span>
            <i data-b="1" className="fa-solid fa-caret-up display"></i>
          </li>
          <li data-index="2" onClick={caret}>
            <i className="fa-solid fa-sliders mini-icons"></i>
            <span className="mini-link-title">
              {lang === "EN"
                ? "Parameters"
                : lang === "BG"
                ? "Параметри"
                : "Parameter"}
            </span>
            <i data-b="0" className="fa-solid fa-caret-up none display"></i>
          </li>

          <li data-index="4" onClick={caret}>
            <i className="fa-solid fa-file mini-icons"></i>
            <span className="mini-link-title">
              {lang === "EN"
                ? "Documents"
                : lang === "BG"
                ? "Документи"
                : "Dokumente"}
            </span>
            <i data-b="3" className="fa-solid fa-caret-up none display"></i>
          </li>
        </ul>
      </div>
      {Number(active.index) === 1 || active.index === null ? (
        <div className="potr-section">
          <h1 style={{ textAlign: "center" }}>
            {lang === "EN"
              ? "Consumer Credit"
              : lang === "BG"
              ? "Потребителски Кредит"
              : "Verbraucherkredit"}
          </h1>
          <div>
            <div id="potr-info">
              {lang === "EN" ? (
                <p
                  style={{
                    fontSize: "2rem",
                  }}
                >
                  Consumer loans are a type of loan in which the client, who is
                  an individual, uses a certain amount given to him by the bank
                  for a certain period of time, having to return not only the
                  total amount of the loan, but also pay interest for the bank
                  service used, which is the price of this credit. In this way,
                  the solvency of the individual client is most often ensured.
                  They satisfy the needs of the population, saving time and
                  stimulating sales of various goods and services, which
                  develops the economy and stabilizes the monetary and fiscal
                  policy in the country.
                </p>
              ) : lang === "BG" ? (
                <p
                  style={{
                    fontSize: "2rem",
                  }}
                >
                  Потребителските кредити са вид заем, при който клиентът, който
                  е физическо лице, използва определена сума, дадена му от
                  банката за определен период от време, като трябва да върне не
                  само общия размер на кредита, но и да заплати лихва за
                  използваната банкова услуга, която е цената на този кредит. По
                  този начин най-често се осигурява платежоспособността на
                  индивидуалния клиент. Те задоволяват потребностите на
                  населението, като се спестява време и се стимулират продажбите
                  на различни стоки и услуги, което развива икономиката и
                  стабилизира провежданата парична и фискална политика в
                  държавата.
                </p>
              ) : (
                <p
                  style={{
                    fontSize: "2rem",
                  }}
                >
                  Verbraucherdarlehen sind eine Art von Darlehen, bei dem der
                  Kunde, der eine natürliche Person ist, einen bestimmten
                  Betrag, der ihm von der Bank gegeben wurde, für einen
                  bestimmten Zeitraum verwendet und nicht nur den Gesamtbetrag
                  des Darlehens zurückzahlen muss , sondern zahlen auch Zinsen
                  für die in Anspruch genommene Bankleistung, die der Preis
                  dieses Kredits ist. Auf diese Weise wird meistens die
                  Zahlungsfähigkeit des einzelnen Kunden sichergestellt. Sie
                  befriedigen die Bedürfnisse der Bevölkerung, sparen Zeit und
                  regen den Verkauf verschiedener Waren und Dienstleistungen an,
                  was die Wirtschaft entwickelt und die Geld- und Fiskalpolitik
                  des Landes stabilisiert.
                </p>
              )}
            </div>
          </div>
        </div>
      ) : null}
      {Number(active.index) === 2 ? (
        <div className="potr-section">
          <h1 style={{ textAlign: "center" }}>
            {lang === "EN"
              ? "Parameters"
              : lang === "BG"
              ? "Параметри"
              : "Parameter"}
          </h1>
          <div id="par-potr">
            <div>
              <i className="fa-solid fa-calendar-days"></i>
              <div>
                <h1>
                  {lang === "EN" ? "Term" : lang === "BG" ? "Срок" : "Laufzeit"}
                </h1>
                <p>
                  {lang === "EN"
                    ? "From 3 to 120 months"
                    : lang === "BG"
                    ? "От 3 до 120 месеца"
                    : "Von 3 bis 120 Monate"}
                </p>
              </div>
            </div>
            <div>
              <i className="fa-sharp fa-solid fa-dollar-sign"></i>
              <div>
                <h1>
                  {lang === "EN"
                    ? "Currency"
                    : lang === "BG"
                    ? "Валута"
                    : "Währung"}
                </h1>
                <p>
                  {lang === "EN" ? "Leva" : lang === "BG" ? "Лева" : "Leva"}
                </p>
              </div>
            </div>
            <div>
              <i className="fa-solid fa-arrow-down"></i>
              <div>
                <h1>
                  {lang === "EN"
                    ? "Minimum size"
                    : lang === "BG"
                    ? "Минимален размер"
                    : "Mindestmaß"}
                </h1>
                <p>
                  {lang === "EN"
                    ? "150 BGN"
                    : lang === "BG"
                    ? "150 лева"
                    : "150 Lv"}
                </p>
              </div>
            </div>
            <div>
              <i className="fa-solid fa-arrow-up"></i>
              <div>
                <h1>
                  {lang === "EN"
                    ? "Maximum size"
                    : lang === "BG"
                    ? "Максимален размер"
                    : "Maximalgröße"}
                </h1>
                <p>
                  {lang === "EN"
                    ? "70,000 BGN"
                    : lang === "BG"
                    ? "70 000 лева"
                    : "70.000 Lv"}
                </p>
              </div>
            </div>
            <div>
              <i className="fa-solid fa-percent"></i>
              <div>
                <h1>
                  {lang === "EN"
                    ? "Interest rate type"
                    : lang === "BG"
                    ? "Вид лихвен процент"
                    : "Art des Zinssatzes"}
                </h1>
                <p>
                  {lang === "EN"
                    ? "Fixed for the entire term of the loan"
                    : lang === "BG"
                    ? "Фиксиран за целия период на кредита"
                    : "Festgelegt für die gesamte Laufzeit des Darlehens"}
                </p>
              </div>
            </div>
            <div>
              <i className="fa-solid fa-file-invoice"></i>
              <div>
                <h1>
                  {lang === "EN"
                    ? "Collateral"
                    : lang === "BG"
                    ? "Обезпечение"
                    : "Sicherheit"}
                </h1>
                <p>
                  {lang === "EN" ? (
                    <>
                      {"Without collateral"} <br />{" "}
                      {"Subject to individual assessment"} <br />{" "}
                      {"according to the client's risk profile."}
                    </>
                  ) : lang === "BG" ? (
                    <>
                      {" "}
                      {"Без обезпечение"} <br />{" "}
                      {"Подлежи на индивидуална преценка"} <br />{" "}
                      {" според рисковия профил на клиента."}
                    </>
                  ) : (
                    <>
                      {"Ohne Sicherheiten"} <br />{" "}
                      {"Vorbehaltlich individueller Prüfung gemäß"} <br />{" "}
                      {"dem Risikoprofil des Kunden."}
                    </>
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {Number(active.index) === 4 ? (
        <div className="potr-section">
          {" "}
          {Number(active.index) === 4 ? (
            <div className="potr-section">
              <p
                style={{
                  borderRadius: "15px",
                  border: "4px #16c680 solid",
                  padding: "2px 5px",
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  marginBottom: "1rem",
                  width: "100%",
                }}
              >
                <a
                  style={{
                    color: "#16c680",
                  }}
                  href={interest_bulletin}
                  download
                >
                  Interest Bulletin - Download
                </a>
              </p>
              {/* <p
                style={{
                  borderRadius: "15px",
                  border: "4px #16c680 solid",
                  padding: "2px 5px",
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  width: "100%",
                }}
              >
                <a
                  style={{
                    color: "#16c680",
                  }}
                  href={tresor_general_terms_and_conditions}
                  download
                >
                  Tresor General Terms And Conditions - Download
                </a>
              </p> */}
            </div>
          ) : null}
        </div>
      ) : null}
      <div id="calc">
        <div>
          <h1>
            {lang === "EN"
              ? "Calculate the monthly payment"
              : lang === "BG"
              ? "Изчислете месечната вноска"
              : "Berechnen Sie die monatliche Zahlung"}
          </h1>
          <div id="sliders">
            <div className="slider-wrapper">
              <p>
                {lang === "EN"
                  ? `I want to receive financing for ${value} BGN`
                  : lang === "BG"
                  ? `Искам да получа финансиране за ${value} BGN`
                  : `Ich möchte eine Finanzierung erhalten für ${value} BGN`}
              </p>
              <input
                type="range"
                min="1000"
                max="70000"
                step="500"
                onChange={(e) => {
                  setValue(Number(e.target.value));
                }}
              />
            </div>
            <div className="slider-wrapper">
              <p>
                {lang === "EN"
                  ? `Repayment term: ${months} months`
                  : lang === "BG"
                  ? `Срок на погасяване: ${months} месеца`
                  : `Rückzahlungsfrist: ${months} monate`}
              </p>
              <input
                defaultValue="3"
                type="range"
                min="3"
                max="120"
                onChange={(e) => {
                  setMonths(Number(e.target.value));
                }}
              />
            </div>
            <p>
              {lang === "EN"
                ? `Monthly payment (${months} months)`
                : lang === "BG"
                ? `Месечна вноска (${months}мес.)`
                : `Monatliche Bezahlung (${months} monate)`}{" "}
              <strong>
                <span id="mth-pay">89.17</span>
                {" BGN"}
              </strong>
            </p>
            <p>
              {lang === "EN"
                ? `Interest rate ${months} months`
                : lang === "BG"
                ? `Лихвен процент (${months}мес.)`
                : `Zinsrate ${months} monate`}{" "}
              <strong>
                <span id="mth-pr">89.17</span>
                {" %"}
              </strong>
            </p>
            <p>
              {lang === "EN"
                ? `Total amount due:`
                : lang === "BG"
                ? `Обща дължима сума:`
                : `Fälliger Gesamtbetrag:`}
              <strong>
                {" "}
                <span id="wh-val">1339</span>
                {" BGN"}
              </strong>
            </p>
          </div>
        </div>
      </div>
      <div id="take">
        <div
          onClick={() => {
            const tkDc = document.getElementById("take");

            try {
              tkDc.children[0].classList.remove("take-hover");
              tkDc.children[0].classList.add("take-flex");

              set_potr_apply(true);
            } catch {}
          }}
          className="take-hover"
        >
          {potr_apply === true ? (
            <>
              <form>
                <input
                  type="email"
                  required={true}
                  maxLength={50}
                  placeholder="Enter your email..."
                />
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    const tkDc = document.getElementById("take");

                    tkDc.children[0].classList.remove("take-flex");

                    set_potr_apply("next");
                  }}
                  type="submit"
                  style={{ background: "green" }}
                >
                  <i className="fa-solid fa-check"></i>
                </button>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    const tkDc = document.getElementById("take");
                    tkDc.children[0].classList.add("take-hover");
                    tkDc.children[0].classList.remove("take-flex");

                    set_potr_apply(false);
                  }}
                  type="button"
                  style={{ background: "red", cursor: "pointer" }}
                >
                  <i className="fa-solid fa-xmark"></i>
                </button>
              </form>
            </>
          ) : potr_apply === "next" ? (
            <h2
              onClick={(e) => {
                e.stopPropagation();
              }}
              style={{ color: "white" }}
            >
              {lang === "EN"
                ? `Request sent!`
                : lang === "BG"
                ? `Заявлението изпратено!`
                : `Anfrage gesendet!`}
            </h2>
          ) : (
            <button>
              {lang === "EN"
                ? `Apply now!`
                : lang === "BG"
                ? `Заявете сега!`
                : `Jetzt Auftragen!`}
            </button>
          )}
        </div>
      </div>
    </>
  );
}

export default PotrCredit;
