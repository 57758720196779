import "./App.css";
import Nav from "../components/nav/Nav";
import Home from "../components/home/Home";
import PotrCredit from "../components/potrCredit/PotrCredit";
import Footer from "../components/footer/footer";
import DebitC from "../components/debitc/Debitc";
import Deposit from "../components/deposit/deposit";
import CreditCard from "../components/creditCard/creditcard";
import CurrAcc from "../components/currAcc/curracc";
import CurrAccf from "../components/currAccf/curraccf";

import { useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Depositf from "../components/depositf/depositf";
import Nabsm from "../components/nabsm/nabsm";
import Tresor from "../components/tresor/tresor";
import Tresorf from "../components/tresorf/tresorf";
import About from "../components/about/about";

function App() {
  const navigate = useNavigate();
  const [lang, setLang] = useState("BG");
  return (
    <>
      <Nav navigate={navigate} lang={lang} setLang={setLang} />

      <Routes>
        <Route
          exact
          path="/"
          element={<Home navigate={navigate} lang={lang} />}
        />
        <Route
          exact
          path="/consumerCredit"
          element={<PotrCredit lang={lang} />}
        />
        <Route exact path="/debitcard" element={<DebitC lang={lang} />} />
        <Route exact path="/deposit" element={<Deposit lang={lang} />} />
        <Route exact path="/depositf" element={<Depositf lang={lang} />} />
        <Route exact path="/nabsm" element={<Nabsm lang={lang} />} />
        <Route exact path="/creditcard" element={<CreditCard lang={lang} />} />
        <Route exact path="/tresor" element={<Tresor lang={lang} />} />
        <Route exact path="/tresorf" element={<Tresorf lang={lang} />} />
        <Route exact path="/curracc" element={<CurrAcc lang={lang} />} />
        <Route exact path="/curraccf" element={<CurrAccf lang={lang} />} />
        <Route exact path="/about" element={<About lang={lang} />} />
      </Routes>
      <Footer navigate={navigate} lang={lang} />
    </>
  );
}

export default App;
