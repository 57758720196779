import "./curracc.css";
import interest_bulletin from "../../assets/docx/interest_bulletin.docx";
import payment_general_terms_and_conditions from "../../assets/docx/paymentacc.doc";
import React from "react";
import { useState, useEffect } from "react";

function CurrAcc({ lang }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [potr_apply, set_potr_apply] = useState(false);

  const [active, setActive] = useState({
    status: false,
    element: null,
    index: null,
  });
  let c;

  function caret(e) {
    if (!active.status) {
      c = {
        status: true,
        element: document.getElementById("main-caret"),
        index: 1,
      };
    } else {
      c = active;
    }
    const main = document.getElementById("main-caret");
    if (
      e.target.id === "main-caret" &&
      !e.target.children[2].classList.contains("none")
    ) {
      setActive({
        status: true,
        element: e.target,
        index: e.target.dataset.index,
      });
    } else {
      c.element.children[2].classList.add("none");
      e.target.children[2].classList.remove("none");
      setActive({
        status: true,
        element: e.target,
        index: e.target.dataset.index,
      });
    }
  }

  return (
    <>
      <div id="deposit-banner"></div>
      <div className="mini-menu">
        <ul className="mini-links">
          <li data-index="1" id="main-caret" onClick={caret}>
            <i className="fa-solid fa-circle-info mini-icons"></i>
            <span className="mini-link-title">
              {lang === "EN"
                ? "Information"
                : lang === "BG"
                ? "Информация"
                : "Information"}
            </span>
            <i data-b="1" className="fa-solid fa-caret-up display"></i>
          </li>
          <li data-index="2" onClick={caret}>
            <i className="fa-solid fa-sliders mini-icons"></i>
            <span className="mini-link-title">
              {lang === "EN"
                ? "Parameters"
                : lang === "BG"
                ? "Параметри"
                : "Parameter"}
            </span>
            <i data-b="0" className="fa-solid fa-caret-up none display"></i>
          </li>

          <li data-index="4" onClick={caret}>
            <i className="fa-solid fa-file mini-icons"></i>
            <span className="mini-link-title">
              {lang === "EN"
                ? "Documents"
                : lang === "BG"
                ? "Документи"
                : "Dokumente"}
            </span>
            <i data-b="3" className="fa-solid fa-caret-up none display"></i>
          </li>
        </ul>
      </div>
      {Number(active.index) === 1 || active.index === null ? (
        <div className="potr-section">
          <h1 style={{ textAlign: "center" }}>
            {lang === "EN"
              ? "Current account - Individuals"
              : lang === "BG"
              ? "Разплащателна сметка - Физически лица "
              : "Aktuelles Konto - Individuen"}
          </h1>
          <div>
            <div id="potr-info">
              {lang === "EN" ? (
                <p
                  style={{
                    fontSize: "2rem",
                  }}
                >
                  The current account is a bank account in which the individual
                  client's funds are stored, giving the possibility to withdraw
                  them at any time and used for non-cash payments between two
                  individuals. These accounts are characterized by the fact that
                  usually no interest is charged and convenience and security
                  are created when storing the capital with individuals and when
                  making various payments. In turn, current accounts also
                  provide easier access to clients' funds, greater flexibility,
                  the possibility of advance financial planning and,
                  accordingly, greater foresight regarding developments in
                  various areas of individual clients' lives. This account can
                  be opened in local or foreign currency.
                </p>
              ) : lang === "BG" ? (
                <p
                  style={{
                    fontSize: "2rem",
                  }}
                >
                  Разплащателната сметка е банкова сметка, в която се съхраняват
                  парични средства на индивидуалния клиент, като се дава
                  възможността те да бъдат изтеглени във всеки момент и се
                  използват за безкасови плащания между две физически лица. За
                  тези сметки е характерно, че обикновено не се начислява лихва
                  и се създава удобство и сигурност при съхранението на капитала
                  при физическите лица и при извършването на различни
                  разплащания. От своя страна разплащателните сметки също
                  осигуряват по-лесен достъп до средствата на клиентите,
                  по-голяма гъвкавост, възможност за предварително финансово
                  планиране и съответно по-обширен кръгозор в бъдещето относно
                  развитието в различни области от живота на индивидуалните
                  клиенти. Тази сметка може да се открие в местна или
                  чуждестранна валута.
                </p>
              ) : (
                <p
                  style={{
                    fontSize: "2rem",
                  }}
                >
                  Das Girokonto ist ein Bankkonto, auf dem die Gelder des
                  einzelnen Kunden gespeichert sind, die Möglichkeit bieten, sie
                  jederzeit abzuheben und für bargeldlose Zahlungen zwischen
                  zwei Einzelpersonen verwendet werden. Diese Konten zeichnen
                  sich dadurch aus, dass in der Regel keine Zinsen verrechnet
                  werden und Komfort und Sicherheit bei der Verwahrung des
                  Kapitals bei Privatpersonen und bei diversen Zahlungen
                  geschaffen werden. Girokonten wiederum bieten auch einen
                  leichteren Zugang zu den Geldern der Kunden, mehr
                  Flexibilität, die Möglichkeit einer finanziellen Vorausplanung
                  und damit eine größere Vorausschau auf Entwicklungen in
                  verschiedenen Lebensbereichen des einzelnen Kunden. Dieses
                  Konto kann in lokaler oder ausländischer Währung eröffnet
                  werden.
                </p>
              )}
            </div>
          </div>
        </div>
      ) : null}
      {Number(active.index) === 2 ? (
        <div className="potr-section">
          <h1 style={{ textAlign: "center" }}>
            {lang === "EN"
              ? "Parameters"
              : lang === "BG"
              ? "Параметри"
              : "Parameter"}
          </h1>
          <div id="par-potr">
            <div>
              <i className="fa-solid fa-coins"></i>
              <div>
                <h1>
                  {lang === "EN"
                    ? "Opening fee"
                    : lang === "BG"
                    ? "Такса за откриване"
                    : "Kontoeröffnungsgebühr"}
                </h1>
                <p>
                  {lang === "EN"
                    ? "Free of charge"
                    : lang === "BG"
                    ? "Безплатно"
                    : "Kostenlos"}
                </p>
              </div>
            </div>
            <div>
              <i className="fa-solid fa-comment-dollar"></i>
              <div>
                <h1>
                  {lang === "EN"
                    ? "Money transfer fee"
                    : lang === "BG"
                    ? "Такса за паричен трансфер "
                    : "Überweisungsgebühr"}
                </h1>
                <p>
                  {lang === "EN"
                    ? "Free of charge"
                    : lang === "BG"
                    ? "Безплатно"
                    : "Kostenlos"}
                </p>
              </div>
            </div>

            <div>
              <i className="fa-solid fa-sack-dollar"></i>
              <div>
                <h1>
                  {lang === "EN"
                    ? "Withdrawal fee"
                    : lang === "BG"
                    ? "Такса за теглене"
                    : "Abhebegebühr"}
                </h1>
                <p>
                  {lang === "EN"
                    ? "Very low for ATMs of our bank"
                    : lang === "BG"
                    ? "Много ниска за банкомати на нашата банка"
                    : "Sehr niedrig für Geldautomaten unserer Bank"}
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {Number(active.index) === 4 ? (
        <div className="potr-section">
          <p
            style={{
              borderRadius: "15px",
              border: "4px #16c680 solid",
              padding: "2px 5px",
              fontSize: "1.5rem",
              fontWeight: "bold",
              marginBottom: "1rem",
              width: "100%",
            }}
          >
            <a
              style={{
                color: "#16c680",
              }}
              href={interest_bulletin}
              download
            >
              Interest Bulletin - Download
            </a>
          </p>
          <p
            style={{
              borderRadius: "15px",
              border: "4px #16c680 solid",
              padding: "2px 5px",
              fontSize: "1.5rem",
              fontWeight: "bold",
              width: "100%",
            }}
          >
            <a
              style={{
                color: "#16c680",
              }}
              href={payment_general_terms_and_conditions}
              download
            >
              Payment Account General Terms And Conditions - Download
            </a>
          </p>
        </div>
      ) : null}

      <div id="take">
        <div
          onClick={() => {
            const tkDc = document.getElementById("take");

            try {
              tkDc.children[0].classList.remove("take-hover");
              tkDc.children[0].classList.add("take-flex");

              set_potr_apply(true);
            } catch {}
          }}
          className="take-hover"
        >
          {potr_apply === true ? (
            <>
              <form>
                <input
                  type="email"
                  required={true}
                  maxLength={50}
                  placeholder="Enter your email..."
                />
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    const tkDc = document.getElementById("take");

                    tkDc.children[0].classList.remove("take-flex");

                    set_potr_apply("next");
                  }}
                  type="submit"
                  style={{ background: "green" }}
                >
                  <i className="fa-solid fa-check"></i>
                </button>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    const tkDc = document.getElementById("take");
                    tkDc.children[0].classList.add("take-hover");
                    tkDc.children[0].classList.remove("take-flex");

                    set_potr_apply(false);
                  }}
                  type="button"
                  style={{ background: "red", cursor: "pointer" }}
                >
                  <i className="fa-solid fa-xmark"></i>
                </button>
              </form>
            </>
          ) : potr_apply === "next" ? (
            <h2
              onClick={(e) => {
                e.stopPropagation();
              }}
              style={{ color: "white" }}
            >
              {lang === "EN"
                ? `Request sent!`
                : lang === "BG"
                ? `Заявлението изпратено!`
                : `Anfrage gesendet!`}
            </h2>
          ) : (
            <button>
              {lang === "EN"
                ? `Apply now!`
                : lang === "BG"
                ? `Заявете сега!`
                : `Jetzt Auftragen!`}
            </button>
          )}
        </div>
      </div>
    </>
  );
}

export default CurrAcc;
