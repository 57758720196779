import "./about.css";
import React from "react";
import { useState, useEffect } from "react";

function About({ lang }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [active, setActive] = useState({
    status: false,
    element: null,
    index: null,
  });

  return (
    <>
      <div id="team-banner"></div>
      <div className="mini-menu"></div>
      {Number(active.index) === 1 || active.index === null ? (
        <>
          <div className="potr-section">
            <h1 style={{ textAlign: "left" }}>
              {lang === "EN"
                ? "History"
                : lang === "BG"
                ? "История"
                : "Geschichte"}
            </h1>
            <div>
              <div className="potr-info">
                {lang === "EN" ? (
                  <p
                    style={{
                      fontSize: "2rem",
                    }}
                  >
                    "Global Invest Bank" is a commercial bank established in
                    2012 and reorganized on 30.09.2021, which is
                    development-oriented and committed to efficient, effective
                    and convenient banking. With our work, we also strive to
                    realize the wishes of our customers in the best possible way
                    and to maintain the cleanliness of the environment. We will
                    provide the best service from our team for your personal and
                    professional needs. We deliver what we promise, deliver on
                    target and deliver on time and promotional quality offers.
                  </p>
                ) : lang === "BG" ? (
                  <p
                    style={{
                      fontSize: "2rem",
                    }}
                  >
                    „Глобал Инвест Банк“ е търговска банка, учредена през 2012
                    г. и преучредена на 30.09.2021 г., която е ориентирана към
                    развитието и е ангажирана с ефективно, резултатно и удобно
                    банкиране. С нашата работа ние също се стремим да
                    реализираме желанията на нашите клиенти възможно по
                    най-добър начин и да поддържаме чистотата на околната среда.
                    Ние ще предоставим най-доброто обслужване от нашия екип за
                    вашите лични и професионални нужди. Даваме това, което
                    обещаваме, изпълняваме целите и ги предоставяме в
                    съответствие с необходимото време и промоционални качествени
                    оферти.
                  </p>
                ) : (
                  <p
                    style={{
                      fontSize: "2rem",
                    }}
                  >
                    „Global Invest Bank“ ist eine gegründete 2012 und zum
                    30.09.2021 neu aufgestellte Geschäftsbank, die
                    entwicklungsorientiert ist und sich einem effizienten,
                    effektiven und bequemen Banking verschrieben hat. Mit
                    unserer Arbeit streben wir auch danach, die Wünsche unserer
                    Kunden bestmöglich zu realisieren und die Sauberkeit der
                    Umwelt zu erhalten. Wir bieten den besten Service von
                    unserem Team für Ihre persönlichen und beruflichen
                    Bedürfnisse. Wir liefern, was wir versprechen, liefern
                    zielgerichtet und liefern pünktlich und qualitativ
                    hochwertige Angebote.
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="potr-section">
            <h1 style={{ textAlign: "right" }}>
              {lang === "EN" ? "Objectives" : lang === "BG" ? "Цели" : "Ziele"}
            </h1>
            <div>
              <div className="potr-info">
                {lang === "EN" ? (
                  <p
                    style={{
                      fontSize: "2rem",
                    }}
                  >
                    The goals-goals that our bank has set for themselves are
                    fast, quality and professional service, provision of
                    comprehensive and efficient services, creation of high
                    standards in business, security and protection of your
                    money.
                  </p>
                ) : lang === "BG" ? (
                  <p
                    style={{
                      fontSize: "2rem",
                    }}
                  >
                    Целите, които нашата банка си е поставила, са бързо,
                    качествено и професионално обслужване, предоставяне на
                    цялостни и ефективни услуги, създаване на високи стандарти в
                    бизнеса, сигурност и защита на Вашите пари
                  </p>
                ) : (
                  <p
                    style={{
                      fontSize: "2rem",
                    }}
                  >
                    Die Ziele, die sich unsere Bank gesetzt hat, sind schneller,
                    qualitativ hochwertiger und professioneller Service,
                    Bereitstellung umfassender und effizienter Dienstleistungen,
                    Schaffung hoher Standards im Geschäft, Sicherheit und Schutz
                    Ihres Geldes.
                  </p>
                )}
              </div>
            </div>
          </div>

          <div className="potr-section">
            <h1 style={{ textAlign: "center", marginBottom: "5rem" }}>
              {lang === "EN" ? "Values" : lang === "BG" ? "Ценности" : "Werte"}
            </h1>
            <div>
              <div className="potr-info">
                <div className="flex-r">
                  <div>
                    <div>
                      {" "}
                      <i className="fa-solid fa-magnifying-glass"></i>
                      <h2>
                        {lang === "EN"
                          ? "Transparency"
                          : lang === "BG"
                          ? "Прозрачност"
                          : "Transparenz"}
                      </h2>
                    </div>
                  </div>
                  <div>
                    <div>
                      <i className="fa-solid fa-square-check"></i>
                      <h2>
                        {lang === "EN"
                          ? "Correctness"
                          : lang === "BG"
                          ? "Коректност"
                          : "Richtigkeit"}
                      </h2>
                    </div>
                  </div>
                  <div>
                    <div>
                      <i className="fa-solid fa-handshake-angle"></i>
                      <h2>
                        {lang === "EN"
                          ? "Trust"
                          : lang === "BG"
                          ? "Доверие"
                          : "Vertrauen"}
                      </h2>
                    </div>
                  </div>

                  <div>
                    <div>
                      <i className="fa-solid fa-person-rays"></i>
                      <h2>
                        {lang === "EN"
                          ? "Respect"
                          : lang === "BG"
                          ? "Уважение"
                          : "Respektieren"}
                      </h2>
                    </div>
                  </div>
                  <div>
                    <div>
                      <i className="fa-solid fa-star"></i>
                      <h2>
                        {lang === "EN"
                          ? "Quality"
                          : lang === "BG"
                          ? "Качество"
                          : "Qualität"}
                      </h2>
                    </div>
                  </div>
                  <div>
                    <div>
                      <i className="fa-solid fa-truck-fast"></i>
                      <h2>
                        {lang === "EN"
                          ? "Efficiency"
                          : lang === "BG"
                          ? "Ефективност"
                          : "Effizienz"}
                      </h2>
                    </div>
                  </div>

                  <div>
                    <div>
                      {" "}
                      <i className="fa-solid fa-code-branch"></i>
                      <h2>
                        {lang === "EN"
                          ? "Innovation"
                          : lang === "BG"
                          ? "Иновативност"
                          : "Innovation"}
                      </h2>
                    </div>
                  </div>
                  <div>
                    <div>
                      {" "}
                      <i className="fa-solid fa-lightbulb"></i>
                      <h2>
                        {lang === "EN"
                          ? "Creativity"
                          : lang === "BG"
                          ? "Креативност"
                          : "Kreativität"}
                      </h2>
                    </div>
                  </div>
                  <div>
                    <div>
                      <i className="fa-solid fa-comments"></i>
                      <h2>
                        {lang === "EN"
                          ? "Empathy"
                          : lang === "BG"
                          ? "Емпатия"
                          : "Empathie"}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="potr-section">
            <h1 style={{ textAlign: "left" }}>
              {lang === "EN" ? "Мission" : lang === "BG" ? "Мисия" : "Auftrag"}
            </h1>
            <div>
              <div className="potr-info">
                {lang === "EN" ? (
                  <p
                    style={{
                      fontSize: "2rem",
                    }}
                  >
                    Our bank "Global Invest Bank" offers checking, deposit,
                    deposit accounts, bank vault, interbank and intrabank
                    payments, collection accounts, debit, credit cards and
                    consumer loans.
                  </p>
                ) : lang === "BG" ? (
                  <p
                    style={{
                      fontSize: "2rem",
                    }}
                  >
                    Нашата банка „Глобал Инвест Банк“ предлага разплащателни,
                    депозитни, влогови сметки, банков трезор, междубанкови и
                    вътрешнобанкови плащания, набирателни сметки, дебитни,
                    кредитни карти и потребителски кредити.
                  </p>
                ) : (
                  <p
                    style={{
                      fontSize: "2rem",
                    }}
                  >
                    Unsere Bank „Global Invest Bank“ bietet Giro-, Einzahlungs-,
                    Einlagenkonten, Banktresor, Interbanken- und
                    Intrabankenzahlungen, Inkassokonten, Debitkarten,
                    Kreditkarten und Verbraucherkredite an.
                  </p>
                )}
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}

export default About;
