import "./curraccf.css";
import interest_bulletin from "../../assets/docx/interest_bulletin.docx";
import payment_general_terms_and_conditions from "../../assets/docx/paymentacc.doc";
import React from "react";
import { useState, useEffect } from "react";

function CurrAccf({ lang }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [potr_apply, set_potr_apply] = useState(false);

  const [active, setActive] = useState({
    status: false,
    element: null,
    index: null,
  });
  let c;

  function caret(e) {
    if (!active.status) {
      c = {
        status: true,
        element: document.getElementById("main-caret"),
        index: 1,
      };
    } else {
      c = active;
    }
    const main = document.getElementById("main-caret");
    if (
      e.target.id === "main-caret" &&
      !e.target.children[2].classList.contains("none")
    ) {
      setActive({
        status: true,
        element: e.target,
        index: e.target.dataset.index,
      });
    } else {
      c.element.children[2].classList.add("none");
      e.target.children[2].classList.remove("none");
      setActive({
        status: true,
        element: e.target,
        index: e.target.dataset.index,
      });
    }
  }

  return (
    <>
      <div id="deposit-banner"></div>
      <div className="mini-menu">
        <ul className="mini-links">
          <li data-index="1" id="main-caret" onClick={caret}>
            <i className="fa-solid fa-circle-info mini-icons"></i>
            <span className="mini-link-title">
              {lang === "EN"
                ? "Information"
                : lang === "BG"
                ? "Информация"
                : "Information"}
            </span>
            <i data-b="1" className="fa-solid fa-caret-up display"></i>
          </li>
          <li data-index="2" onClick={caret}>
            <i className="fa-solid fa-sliders mini-icons"></i>
            <span className="mini-link-title">
              {lang === "EN"
                ? "Parameters"
                : lang === "BG"
                ? "Параметри"
                : "Parameter"}
            </span>
            <i data-b="0" className="fa-solid fa-caret-up none display"></i>
          </li>

          <li data-index="4" onClick={caret}>
            <i className="fa-solid fa-file mini-icons"></i>
            <span className="mini-link-title">
              {lang === "EN"
                ? "Documents"
                : lang === "BG"
                ? "Документи"
                : "Dokumente"}
            </span>
            <i data-b="3" className="fa-solid fa-caret-up none display"></i>
          </li>
        </ul>
      </div>
      {Number(active.index) === 1 || active.index === null ? (
        <div className="potr-section">
          <h1 style={{ textAlign: "center" }}>
            {lang === "EN"
              ? "Current account - Corporate entities"
              : lang === "BG"
              ? "Разплащателна сметка - Юридически лица"
              : "Aktuelles Konto - Juristische Personen"}
          </h1>
          <div>
            <div id="potr-info">
              {lang === "EN" ? (
                <p
                  style={{
                    fontSize: "2rem",
                  }}
                >
                  Current account is a bank account in which funds of corporate
                  customers and small businesses are stored, giving the
                  possibility to withdraw them at any time by a representative
                  of the legal entity or by an authorized person for that person
                  and are used for various financial business operations between
                  two enterprises. These accounts are characterized by the fact
                  that usually no interest is charged and convenience and
                  security are created when storing cash with legal entities and
                  when making various payments related to the main activity of
                  the organization itself. On the other hand, current accounts
                  also provide these corporate clients with easier access to
                  capital funds, greater flexibility, the possibility of advance
                  financial planning and goal setting, respectively easier
                  control of the financial result and predictability of the
                  financial situation in the future due to good organization of
                  capital funds. This account can be opened in local or foreign
                  currency.
                </p>
              ) : lang === "BG" ? (
                <p
                  style={{
                    fontSize: "2rem",
                  }}
                >
                  Рразплащателната сметка е банкова сметка, в която се
                  съхраняват парични средства на корпоративните клиенти и малкия
                  бизнес, като се дава възможността те да бъдат изтеглени във
                  всеки момент от представител на юридическото лице или от
                  упълномощено за това лице и се използват за различни финансови
                  стопански операции между две предприятия. За тези сметки е
                  характерно, че обикновено не се начислява лихва и се създава
                  удобство и сигурност при съхранението на паричните средства
                  при юридическите лица и при извършването на различни плащания,
                  свързани с основната дейност на самата организация. От своя
                  страна разплащателните сметки също осигуряват по-лесен достъп
                  до капиталовите средства на тези корпоративни клиенти,
                  по-голяма гъвкавост, възможност за предварително финансово
                  планиране и целеполагане, съответно по-лесно контролиране на
                  финансовия резултат и предвидимост на финансовото състояние в
                  бъдеще поради добрата организация на капиталовите средства.
                  Тази сметка може да се открие в местна или чуждестранна
                  валута.
                </p>
              ) : (
                <p
                  style={{
                    fontSize: "2rem",
                  }}
                >
                  Das Girokonto ist ein Bankkonto, auf dem Gelder von
                  Firmenkunden und kleinen Unternehmen gespeichert werden, wobei
                  die Möglichkeit besteht, diese jederzeit durch einen Vertreter
                  der juristischen Person oder durch eine dazu bevollmächtigte
                  Person abzuheben Person und werden für verschiedene
                  Finanzgeschäfte zwischen zwei Unternehmen verwendet. Diese
                  Konten zeichnen sich dadurch aus, dass normalerweise keine
                  Zinsen berechnet werden und Komfort und Sicherheit geschaffen
                  werden, wenn Bargeld bei juristischen Personen aufbewahrt und
                  verschiedene Zahlungen im Zusammenhang mit der Haupttätigkeit
                  der Organisation selbst geleistet werden. Andererseits bieten
                  Girokonten diesen Firmenkunden auch einen leichteren Zugang zu
                  Kapital, größere Flexibilität, die Möglichkeit einer
                  finanziellen Vorausplanung und Zielsetzung bzw. eine leichtere
                  Kontrolle des Finanzergebnisses und eine Vorhersehbarkeit der
                  finanziellen Situation in der Zukunft gute Organisation von
                  Kapitalfonds. Dieses Konto kann in lokaler oder ausländischer
                  Währung eröffnet werden.
                </p>
              )}
            </div>
          </div>
        </div>
      ) : null}
      {Number(active.index) === 2 ? (
        <div className="potr-section">
          <h1 style={{ textAlign: "center" }}>
            {lang === "EN"
              ? "Parameters"
              : lang === "BG"
              ? "Параметри"
              : "Parameter"}
          </h1>
          <div id="par-potr">
            <div>
              <i className="fa-solid fa-coins"></i>
              <div>
                <h1>
                  {lang === "EN"
                    ? "Opening fee"
                    : lang === "BG"
                    ? "Такса за откриване"
                    : "Kontoeröffnungsgebühr"}
                </h1>
                <p>
                  {lang === "EN"
                    ? "Free of charge"
                    : lang === "BG"
                    ? "Безплатно"
                    : "Kostenlos"}
                </p>
              </div>
            </div>
            <div>
              <i className="fa-solid fa-comment-dollar"></i>
              <div>
                <h1>
                  {lang === "EN"
                    ? "Money transfer fee"
                    : lang === "BG"
                    ? "Такса за паричен трансфер "
                    : "Überweisungsgebühr"}
                </h1>
                <p>
                  {lang === "EN"
                    ? "Free of charge"
                    : lang === "BG"
                    ? "Безплатно"
                    : "Kostenlos"}
                </p>
              </div>
            </div>

            <div>
              <i className="fa-solid fa-sack-dollar"></i>
              <div>
                <h1>
                  {lang === "EN"
                    ? "Withdrawal fee"
                    : lang === "BG"
                    ? "Такса за теглене"
                    : "Abhebegebühr"}
                </h1>
                <p>
                  {lang === "EN"
                    ? "Very low for ATMs of our bank"
                    : lang === "BG"
                    ? "Много ниска за банкомати на нашата банка"
                    : "Sehr niedrig für Geldautomaten unserer Bank"}
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {Number(active.index) === 4 ? (
        <div className="potr-section">
          <p
            style={{
              borderRadius: "15px",
              border: "4px #16c680 solid",
              padding: "2px 5px",
              fontSize: "1.5rem",
              fontWeight: "bold",
              marginBottom: "1rem",
              width: "100%",
            }}
          >
            <a
              style={{
                color: "#16c680",
              }}
              href={interest_bulletin}
              download
            >
              Interest Bulletin - Download
            </a>
          </p>
          <p
            style={{
              borderRadius: "15px",
              border: "4px #16c680 solid",
              padding: "2px 5px",
              fontSize: "1.5rem",
              fontWeight: "bold",
              width: "100%",
            }}
          >
            <a
              style={{
                color: "#16c680",
              }}
              href={payment_general_terms_and_conditions}
              download
            >
              Payment Account General Terms And Conditions - Download
            </a>
          </p>
        </div>
      ) : null}

      <div id="take">
        <div
          onClick={() => {
            const tkDc = document.getElementById("take");

            try {
              tkDc.children[0].classList.remove("take-hover");
              tkDc.children[0].classList.add("take-flex");

              set_potr_apply(true);
            } catch {}
          }}
          className="take-hover"
        >
          {potr_apply === true ? (
            <>
              <form>
                <input
                  type="email"
                  required={true}
                  maxLength={50}
                  placeholder="Enter your email..."
                />
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    const tkDc = document.getElementById("take");

                    tkDc.children[0].classList.remove("take-flex");

                    set_potr_apply("next");
                  }}
                  type="submit"
                  style={{ background: "green" }}
                >
                  <i className="fa-solid fa-check"></i>
                </button>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    const tkDc = document.getElementById("take");
                    tkDc.children[0].classList.add("take-hover");
                    tkDc.children[0].classList.remove("take-flex");

                    set_potr_apply(false);
                  }}
                  type="button"
                  style={{ background: "red", cursor: "pointer" }}
                >
                  <i className="fa-solid fa-xmark"></i>
                </button>
              </form>
            </>
          ) : potr_apply === "next" ? (
            <h2
              onClick={(e) => {
                e.stopPropagation();
              }}
              style={{ color: "white" }}
            >
              {lang === "EN"
                ? `Request sent!`
                : lang === "BG"
                ? `Заявлението изпратено!`
                : `Anfrage gesendet!`}
            </h2>
          ) : (
            <button>
              {lang === "EN"
                ? `Apply now!`
                : lang === "BG"
                ? `Заявете сега!`
                : `Jetzt Auftragen!`}
            </button>
          )}
        </div>
      </div>
    </>
  );
}

export default CurrAccf;
