import "./deposit.css";
import interest_bulletin from "../../assets/docx/interest_bulletin.docx";
import deposit_general_terms_and_conditions from "../../assets/docx/Deposit.doc";
import React from "react";
import { useState, useEffect } from "react";

function Deposit({ lang }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [potr_apply, set_potr_apply] = useState(false);

  const [active, setActive] = useState({
    status: false,
    element: null,
    index: null,
  });
  let c;

  function caret(e) {
    if (!active.status) {
      c = {
        status: true,
        element: document.getElementById("main-caret"),
        index: 1,
      };
    } else {
      c = active;
    }
    const main = document.getElementById("main-caret");
    if (
      e.target.id === "main-caret" &&
      !e.target.children[2].classList.contains("none")
    ) {
      setActive({
        status: true,
        element: e.target,
        index: e.target.dataset.index,
      });
    } else {
      c.element.children[2].classList.add("none");
      e.target.children[2].classList.remove("none");
      setActive({
        status: true,
        element: e.target,
        index: e.target.dataset.index,
      });
    }
  }

  return (
    <>
      <div id="deposit-banner"></div>
      <div className="mini-menu">
        <ul className="mini-links">
          <li data-index="1" id="main-caret" onClick={caret}>
            <i className="fa-solid fa-circle-info mini-icons"></i>
            <span className="mini-link-title">
              {lang === "EN"
                ? "Information"
                : lang === "BG"
                ? "Информация"
                : "Information"}
            </span>
            <i data-b="1" className="fa-solid fa-caret-up display"></i>
          </li>
          <li data-index="2" onClick={caret}>
            <i className="fa-solid fa-sliders mini-icons"></i>
            <span className="mini-link-title">
              {lang === "EN"
                ? "Parameters"
                : lang === "BG"
                ? "Параметри"
                : "Parameter"}
            </span>
            <i data-b="0" className="fa-solid fa-caret-up none display"></i>
          </li>

          <li data-index="4" onClick={caret}>
            <i className="fa-solid fa-file mini-icons"></i>
            <span className="mini-link-title">
              {lang === "EN"
                ? "Documents"
                : lang === "BG"
                ? "Документи"
                : "Dokumente"}
            </span>
            <i data-b="3" className="fa-solid fa-caret-up none display"></i>
          </li>
        </ul>
      </div>
      {Number(active.index) === 1 || active.index === null ? (
        <div className="potr-section">
          <h1 style={{ textAlign: "center" }}>
            {lang === "EN"
              ? "Deposit account - Individuals"
              : lang === "BG"
              ? "Депозитна сметка - Физически лица"
              : "Depotkonto - Individuen"}
          </h1>
          <div>
            <div id="potr-info">
              {lang === "EN" ? (
                <p
                  style={{
                    fontSize: "2rem",
                  }}
                >
                  The deposit account is a bank account in which the financial
                  resources of an individual customer are stored for a certain
                  period, and through this service the bank collects its
                  attracted capital, with the help of which it performs its
                  credit services. Due to this fact, the bank pays the
                  individual client a certain amount of money for the period
                  specified in the contract in the form of deposit interest. It
                  is possible to withdraw the money on these accounts before the
                  expiry of the relevant term, but this means the loss of part
                  of the interest and the payment of penal interest by the
                  individual. This account can be opened in local or foreign
                  currency.
                </p>
              ) : lang === "BG" ? (
                <p
                  style={{
                    fontSize: "2rem",
                  }}
                >
                  Депозитната сметка е банкова сметка, в която се съхраняват
                  финансовите средства на даден индивидуален клиент за определен
                  срок, като чрез тази услуга банката набира своя привлечен
                  капитал, с помощта на който тя извършва кредитните си услуги.
                  Поради този факт банката изплаща на индивидуалния клиент
                  дадена парична сума спрямо посочения период в договора под
                  формата на депозитна лихва. Има възможност парите по тези
                  сметки да се изтеглят преди изтичането на съответния срок, но
                  това означава загуба на част от лихвата и плащане на
                  наказателна лихва от страна на физическото лице. Тази сметка
                  може да се открие в местна или чуждестранна валута.
                </p>
              ) : (
                <p
                  style={{
                    fontSize: "2rem",
                  }}
                >
                  Ein Einzahlungskonto ist ein Bankkonto, auf dem die
                  finanziellen Mittel eines einzelnen Kunden für einen
                  bestimmten Zeitraum gespeichert werden, und durch diese
                  Dienstleistung sammelt die Bank ihr angezogenes Kapital, mit
                  dessen Hilfe sie ihren Kredit ausführt Dienstleistungen. Aus
                  diesem Grund zahlt die Bank dem einzelnen Kunden für den im
                  Vertrag festgelegten Zeitraum einen bestimmten Geldbetrag in
                  Form von Depotzinsen. Es ist möglich, das Geld auf diesen
                  Konten vor Ablauf der entsprechenden Frist abzuheben, dies
                  bedeutet jedoch den Verlust eines Teils der Zinsen und die
                  Zahlung von Strafzinsen durch den Einzelnen. Dieses Konto kann
                  in lokaler oder ausländischer Währung eröffnet werden.
                </p>
              )}
            </div>
          </div>
        </div>
      ) : null}
      {Number(active.index) === 2 ? (
        <div className="potr-section">
          <h1 style={{ textAlign: "center" }}>
            {lang === "EN"
              ? "Parameters"
              : lang === "BG"
              ? "Параметри"
              : "Parameter"}
          </h1>
          <div id="par-potr">
            <div>
              <i className="fa-sharp fa-solid fa-dollar-sign"></i>
              <div>
                <h1>
                  {lang === "EN"
                    ? "Currency"
                    : lang === "BG"
                    ? "Валута"
                    : "Währung"}
                </h1>
                <p>
                  {lang === "EN"
                    ? "Leva / Euro"
                    : lang === "BG"
                    ? "Лева / Евро"
                    : "Leva / Euro"}
                </p>
              </div>
            </div>
            <div>
              <i className="fa-solid fa-percent"></i>
              <div>
                <h1>
                  {lang === "EN"
                    ? "Deposit interest"
                    : lang === "BG"
                    ? "Депозитна лихва"
                    : "Zinsen hinterlegen"}
                </h1>
                <p>
                  {lang === "EN"
                    ? "From 0.75% to 1% per year"
                    : lang === "BG"
                    ? "От 0.75% до 1% годишно"
                    : "Von 0,75 % bis 1 % pro Jahr"}
                </p>
              </div>
            </div>

            <div>
              <i className="fa-regular fa-clock"></i>
              <div>
                <h1>
                  {lang === "EN"
                    ? "Term of the deposit"
                    : lang === "BG"
                    ? "Срок на депозита"
                    : "Laufzeit der Einzahlung"}
                </h1>
                <p>
                  {lang === "EN"
                    ? "From 1 to 12 months"
                    : lang === "BG"
                    ? "От 1 до 12 месеца"
                    : "Von 1 bis 12 Monate"}
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {Number(active.index) === 4 ? (
        <div className="potr-section">
          <p
            style={{
              borderRadius: "15px",
              border: "4px #16c680 solid",
              padding: "2px 5px",
              fontSize: "1.5rem",
              fontWeight: "bold",
              marginBottom: "1rem",
              width: "100%",
            }}
          >
            <a
              style={{
                color: "#16c680",
              }}
              href={interest_bulletin}
              download
            >
              Interest Bulletin - Download
            </a>
          </p>
          <p
            style={{
              borderRadius: "15px",
              border: "4px #16c680 solid",
              padding: "2px 5px",
              fontSize: "1.5rem",
              fontWeight: "bold",
              width: "100%",
            }}
          >
            <a
              style={{
                color: "#16c680",
              }}
              href={deposit_general_terms_and_conditions}
              download
            >
              Deposit General Terms And Conditions - Download
            </a>
          </p>
        </div>
      ) : null}

      <div id="take">
        <div
          onClick={() => {
            const tkDc = document.getElementById("take");

            try {
              tkDc.children[0].classList.remove("take-hover");
              tkDc.children[0].classList.add("take-flex");

              set_potr_apply(true);
            } catch {}
          }}
          className="take-hover"
        >
          {potr_apply === true ? (
            <>
              <form>
                <input
                  type="email"
                  required={true}
                  maxLength={50}
                  placeholder="Enter your email..."
                />
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    const tkDc = document.getElementById("take");

                    tkDc.children[0].classList.remove("take-flex");

                    set_potr_apply("next");
                  }}
                  type="submit"
                  style={{ background: "green" }}
                >
                  <i className="fa-solid fa-check"></i>
                </button>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    const tkDc = document.getElementById("take");
                    tkDc.children[0].classList.add("take-hover");
                    tkDc.children[0].classList.remove("take-flex");

                    set_potr_apply(false);
                  }}
                  type="button"
                  style={{ background: "red", cursor: "pointer" }}
                >
                  <i className="fa-solid fa-xmark"></i>
                </button>
              </form>
            </>
          ) : potr_apply === "next" ? (
            <h2
              onClick={(e) => {
                e.stopPropagation();
              }}
              style={{ color: "white" }}
            >
              {lang === "EN"
                ? `Request sent!`
                : lang === "BG"
                ? `Заявлението изпратено!`
                : `Anfrage gesendet!`}
            </h2>
          ) : (
            <button>
              {lang === "EN"
                ? `Apply now!`
                : lang === "BG"
                ? `Заявете сега!`
                : `Jetzt Auftragen!`}
            </button>
          )}
        </div>
      </div>
    </>
  );
}

export default Deposit;
