import "./debitc.css";
import interest_bulletin from "../../assets/docx/interest_bulletin.docx";
import general_terms_and_conditions from "../../assets/docx/general_terms_and_conditions.doc";
import React from "react";
import { useState, useEffect } from "react";

function DebitC({ lang }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [potr_apply, set_potr_apply] = useState(false);

  const [active, setActive] = useState({
    status: false,
    element: null,
    index: null,
  });
  let c;

  function caret(e) {
    if (!active.status) {
      c = {
        status: true,
        element: document.getElementById("main-caret"),
        index: 1,
      };
    } else {
      c = active;
    }
    const main = document.getElementById("main-caret");
    if (
      e.target.id === "main-caret" &&
      !e.target.children[2].classList.contains("none")
    ) {
      setActive({
        status: true,
        element: e.target,
        index: e.target.dataset.index,
      });
    } else {
      c.element.children[2].classList.add("none");
      e.target.children[2].classList.remove("none");
      setActive({
        status: true,
        element: e.target,
        index: e.target.dataset.index,
      });
    }
  }

  return (
    <>
      <div id="debit-banner"></div>
      <div className="mini-menu">
        <ul className="mini-links">
          <li data-index="1" id="main-caret" onClick={caret}>
            <i className="fa-solid fa-circle-info mini-icons"></i>
            <span className="mini-link-title">
              {lang === "EN"
                ? "Information"
                : lang === "BG"
                ? "Информация"
                : "Information"}
            </span>
            <i data-b="1" className="fa-solid fa-caret-up display"></i>
          </li>
          <li data-index="2" onClick={caret}>
            <i className="fa-solid fa-sliders mini-icons"></i>
            <span className="mini-link-title">
              {lang === "EN"
                ? "Parameters"
                : lang === "BG"
                ? "Параметри"
                : "Parameter"}
            </span>
            <i data-b="0" className="fa-solid fa-caret-up none display"></i>
          </li>

          <li data-index="4" onClick={caret}>
            <i className="fa-solid fa-file mini-icons"></i>
            <span className="mini-link-title">
              {lang === "EN"
                ? "Documents"
                : lang === "BG"
                ? "Документи"
                : "Dokumente"}
            </span>
            <i data-b="3" className="fa-solid fa-caret-up none display"></i>
          </li>
        </ul>
      </div>
      {Number(active.index) === 1 || active.index === null ? (
        <div className="potr-section">
          <h1 style={{ textAlign: "center" }}>
            {lang === "EN"
              ? "Debit card"
              : lang === "BG"
              ? "Дебитна карта"
              : "Debitkarte"}
          </h1>
          <div>
            <div id="potr-info">
              {lang === "EN" ? (
                <p
                  style={{
                    fontSize: "2rem",
                  }}
                >
                  Debit card is a type of payment instrument for purchases of
                  various goods or services and it can also be used to receive
                  money such as storing savings, transferring money to another
                  bank account and so on. In this way, quality and strategic
                  management of financial resources is ensured, these bank cards
                  also play an important role in digital banking, creating
                  convenience of payment and a greater possibility of preventing
                  financial fraud. Time is saved for the implementation of
                  various business operations, as it is practically portable and
                  enables more flexible planning of purchases and other
                  financial actions with this card. It is also possible to
                  withdraw directly from the bank account.
                </p>
              ) : lang === "BG" ? (
                <p
                  style={{
                    fontSize: "2rem",
                  }}
                >
                  Дебитната карта е вид разплащателно средство за покупки на
                  различни стоки или услуги, като тя може също да се използва и
                  за получаване на пари като съхраняване на спестявания,
                  прехвърляне на пари в друга банкова сметка и така нататък. По
                  този начин се осигурява качествено и стратегическо управление
                  на финансовите средства, тези банкови карти играят също важна
                  роля в дигиталното банкиране, като създават удобство на
                  плащане и по-голяма възможност за превенция на финансови
                  измами. Пести се време за реализирането на различните
                  стопански операции, като е практически преносима и дава
                  възможност за по-еластично планиране на покупки и други
                  финансови действия с тази карта. Дава се възможност също така
                  за директно теглене от банковата сметка.
                </p>
              ) : (
                <p
                  style={{
                    fontSize: "2rem",
                  }}
                >
                  Debitkarte ist eine Art Zahlungsinstrument für den Kauf
                  verschiedener Waren oder Dienstleistungen und kann auch
                  verwendet werden, um Geld zu erhalten, z. B. um Ersparnisse
                  aufzubewahren, Geld auf ein anderes Bankkonto zu überweisen
                  und so weiter. Auf diese Weise wird die Qualität und
                  strategische Verwaltung der Finanzmittel sichergestellt, diese
                  Bankkarten spielen auch eine wichtige Rolle im digitalen
                  Banking, schaffen Zahlungskomfort und eine größere
                  Möglichkeit, Finanzbetrug zu verhindern. Zeitersparnis bei der
                  Durchführung verschiedener Geschäftsvorgänge, da sie praktisch
                  tragbar ist und eine flexiblere Planung von Einkäufen und
                  anderen finanziellen Aktionen mit dieser Karte ermöglicht. Es
                  ist auch möglich, direkt vom Bankkonto abzuheben.
                </p>
              )}
            </div>
          </div>
        </div>
      ) : null}
      {Number(active.index) === 2 ? (
        <div className="potr-section">
          <h1 style={{ textAlign: "center" }}>
            {lang === "EN"
              ? "Parameters"
              : lang === "BG"
              ? "Параметри"
              : "Parameter"}
          </h1>
          <div id="par-potr">
            <div>
              <i className="fa-sharp fa-solid fa-dollar-sign"></i>
              <div>
                <h1>
                  {lang === "EN"
                    ? "Currency"
                    : lang === "BG"
                    ? "Валута"
                    : "Währung"}
                </h1>
                <p>
                  {lang === "EN"
                    ? "Leva / Euro"
                    : lang === "BG"
                    ? "Лева / Евро"
                    : "Leva / Euro"}
                </p>
              </div>
            </div>
            <div>
              <i className="fa-solid fa-arrow-down"></i>
              <div>
                <h1>
                  {lang === "EN"
                    ? "Minimum size"
                    : lang === "BG"
                    ? "Минимален размер"
                    : "Mindestmaß"}
                </h1>
                <p>
                  {lang === "EN"
                    ? "10 BGN / 5 Euro"
                    : lang === "BG"
                    ? "10 лева / 5 евро"
                    : "10 Lv / 5 Euro"}
                </p>
              </div>
            </div>

            <div>
              <i className="fa-regular fa-clock"></i>
              <div>
                <h1>
                  {lang === "EN"
                    ? "Card validity"
                    : lang === "BG"
                    ? "Валидност на картата"
                    : "Kartengültigkeit"}
                </h1>
                <p>
                  {lang === "EN"
                    ? "3 years"
                    : lang === "BG"
                    ? "3 години "
                    : "3 Jahre"}
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {Number(active.index) === 4 ? (
        <div className="potr-section">
          <p
            style={{
              borderRadius: "15px",
              border: "4px #16c680 solid",
              padding: "2px 5px",
              fontSize: "1.5rem",
              fontWeight: "bold",
              marginBottom: "1rem",
              width: "100%",
            }}
          >
            <a
              style={{
                color: "#16c680",
              }}
              href={interest_bulletin}
              download
            >
              Interest Bulletin - Download
            </a>
          </p>
          <p
            style={{
              borderRadius: "15px",
              border: "4px #16c680 solid",
              padding: "2px 5px",
              fontSize: "1.5rem",
              fontWeight: "bold",
              width: "100%",
            }}
          >
            <a
              style={{
                color: "#16c680",
              }}
              href={general_terms_and_conditions}
              download
            >
              General Terms And Conditions - Download
            </a>
          </p>
        </div>
      ) : null}

      <div id="take">
        <div
          onClick={() => {
            const tkDc = document.getElementById("take");

            try {
              tkDc.children[0].classList.remove("take-hover");
              tkDc.children[0].classList.add("take-flex");

              set_potr_apply(true);
            } catch {}
          }}
          className="take-hover"
        >
          {potr_apply === true ? (
            <>
              <form>
                <input
                  type="email"
                  required={true}
                  maxLength={50}
                  placeholder="Enter your email..."
                />
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    const tkDc = document.getElementById("take");

                    tkDc.children[0].classList.remove("take-flex");

                    set_potr_apply("next");
                  }}
                  type="submit"
                  style={{ background: "green" }}
                >
                  <i className="fa-solid fa-check"></i>
                </button>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    const tkDc = document.getElementById("take");
                    tkDc.children[0].classList.add("take-hover");
                    tkDc.children[0].classList.remove("take-flex");

                    set_potr_apply(false);
                  }}
                  type="button"
                  style={{ background: "red", cursor: "pointer" }}
                >
                  <i className="fa-solid fa-xmark"></i>
                </button>
              </form>
            </>
          ) : potr_apply === "next" ? (
            <h2
              onClick={(e) => {
                e.stopPropagation();
              }}
              style={{ color: "white" }}
            >
              {lang === "EN"
                ? `Request sent!`
                : lang === "BG"
                ? `Заявлението изпратено!`
                : `Anfrage gesendet!`}
            </h2>
          ) : (
            <button>
              {lang === "EN"
                ? `Apply now!`
                : lang === "BG"
                ? `Заявете сега!`
                : `Jetzt Auftragen!`}
            </button>
          )}
        </div>
      </div>
    </>
  );
}

export default DebitC;
