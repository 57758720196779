import "./tresorf.css";
import interest_bulletin from "../../assets/docx/interest_bulletin.docx";
import tresor_general_terms_and_conditions from "../../assets/docx/tresor.doc";
import React from "react";
import { useState, useEffect } from "react";

function Tresorf({ lang }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [potr_apply, set_potr_apply] = useState(false);

  const [active, setActive] = useState({
    status: false,
    element: null,
    index: null,
  });
  let c;

  function caret(e) {
    if (!active.status) {
      c = {
        status: true,
        element: document.getElementById("main-caret"),
        index: 1,
      };
    } else {
      c = active;
    }
    const main = document.getElementById("main-caret");
    if (
      e.target.id === "main-caret" &&
      !e.target.children[2].classList.contains("none")
    ) {
      setActive({
        status: true,
        element: e.target,
        index: e.target.dataset.index,
      });
    } else {
      c.element.children[2].classList.add("none");
      e.target.children[2].classList.remove("none");
      setActive({
        status: true,
        element: e.target,
        index: e.target.dataset.index,
      });
    }
  }

  return (
    <>
      <div id="tresor-banner"></div>
      <div className="mini-menu">
        <ul className="mini-links">
          <li data-index="1" id="main-caret" onClick={caret}>
            <i className="fa-solid fa-circle-info mini-icons"></i>
            <span className="mini-link-title">
              {lang === "EN"
                ? "Information"
                : lang === "BG"
                ? "Информация"
                : "Information"}
            </span>
            <i data-b="1" className="fa-solid fa-caret-up display"></i>
          </li>
          <li data-index="2" onClick={caret}>
            <i className="fa-solid fa-sliders mini-icons"></i>
            <span className="mini-link-title">
              {lang === "EN"
                ? "Parameters"
                : lang === "BG"
                ? "Параметри"
                : "Parameter"}
            </span>
            <i data-b="0" className="fa-solid fa-caret-up none display"></i>
          </li>

          <li data-index="4" onClick={caret}>
            <i className="fa-solid fa-file mini-icons"></i>
            <span className="mini-link-title">
              {lang === "EN"
                ? "Documents"
                : lang === "BG"
                ? "Документи"
                : "Dokumente"}
            </span>
            <i data-b="3" className="fa-solid fa-caret-up none display"></i>
          </li>
        </ul>
      </div>
      {Number(active.index) === 1 || active.index === null ? (
        <div className="potr-section">
          <h1 style={{ textAlign: "center" }}>
            {lang === "EN"
              ? "Vault - Legal Entities"
              : lang === "BG"
              ? "Трезор - Юридически лица"
              : "Schatzkammer - Juristische Personen"}
          </h1>
          <div>
            <div id="potr-info">
              {lang === "EN" ? (
                <p
                  style={{
                    fontSize: "2rem",
                  }}
                >
                  The bank vault is a separate room with metal safes that the
                  bank provides to its corporate clients for temporary use under
                  certain conditions. Money, documents and valuables can be
                  stored there under strict security. The bank guarantees the
                  security, integrity, confidentiality and secrecy of the
                  contents of the safes. When using this banking service, the
                  legal entity pays various fees specified in the bank tariff,
                  such as a fee for renting and using a bank vault, a fee for
                  handling money, and others. The rental fee is determined
                  according to the size of the room and the rental period.
                </p>
              ) : lang === "BG" ? (
                <p
                  style={{
                    fontSize: "2rem",
                  }}
                >
                  Банковият трезор е обособено помещение с метални сейфове,
                  които банката предоставя на своите корпоративни клиенти за
                  временно ползване при определени условия. Там могат да се
                  съхраняват пари, документи и ценности под строг охраняем
                  режим. Банката гарантира сигурността, неприкосновеността,
                  поверителността и секретността на съдържанието на сейфовете.
                  При ползването на тази банкова услуга юридическото лице
                  заплаща различни такси, определени в банковата тарифа като
                  например такса за наемане и ползване на банков трезор, такса
                  за обработка на парите и други. Таксата за наемане се определя
                  в съответствие с размера на помещението и срока на наемането.
                </p>
              ) : (
                <p
                  style={{
                    fontSize: "2rem",
                  }}
                >
                  Der Banktresor ist ein separater Raum mit Tresoren aus Metall,
                  den die Bank ihren Firmenkunden unter bestimmten Bedingungen
                  zur vorübergehenden Nutzung zur Verfügung stellt. Geld,
                  Dokumente und Wertgegenstände können dort unter strenger
                  Sicherheit aufbewahrt werden. Die Bank garantiert die
                  Sicherheit, Integrität, Vertraulichkeit und Geheimhaltung des
                  Inhalts der Tresore. Bei der Nutzung dieser Bankdienstleistung
                  zahlt die juristische Person verschiedene Gebühren, die im
                  Banktarif festgelegt sind, wie z. Der Mietpreis richtet sich
                  nach der Raumgröße und der Mietdauer.
                </p>
              )}
            </div>
          </div>
        </div>
      ) : null}
      {Number(active.index) === 2 ? (
        <div className="potr-section">
          <h1 style={{ textAlign: "center" }}>
            {lang === "EN"
              ? "Parameters"
              : lang === "BG"
              ? "Параметри"
              : "Parameter"}
          </h1>
          <div id="par-potr">
            <div>
              <i className="fa-solid fa-ruler-combined"></i>
              <div>
                <h1>
                  {lang === "EN"
                    ? "Vault size"
                    : lang === "BG"
                    ? "Размер"
                    : "Tresorgröße"}
                </h1>
                <p>
                  {lang === "EN"
                    ? "Three different sizes"
                    : lang === "BG"
                    ? "Три различни размера "
                    : "Drei verschiedene Gewölbegrößen"}
                </p>
              </div>
            </div>
            <div>
              <i className="fa-regular fa-clock"></i>
              <div>
                <h1>
                  {lang === "EN"
                    ? "Term of use"
                    : lang === "BG"
                    ? "Срок на ползване"
                    : "Nutzungsbedinungen"}
                </h1>
                <p>
                  {lang === "EN"
                    ? "From 1 to 12 months"
                    : lang === "BG"
                    ? "От 1 до  12 месеца"
                    : "Von 1 bis 12 Monate"}
                </p>
              </div>
            </div>

            <div>
              <i className="fa-solid fa-shield-halved"></i>
              <div>
                <h1>
                  {lang === "EN"
                    ? "Protection"
                    : lang === "BG"
                    ? "Защита"
                    : "Schutz"}
                </h1>
                <p>
                  {lang === "EN"
                    ? "Next-generation protection for your valuables"
                    : lang === "BG"
                    ? "Защита от последно поколение, за вашите ценности"
                    : "Schutz der letzten Generation für Ihre Wertgegenstände"}
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {Number(active.index) === 4 ? (
        <div className="potr-section">
          <p
            style={{
              borderRadius: "15px",
              border: "4px #16c680 solid",
              padding: "2px 5px",
              fontSize: "1.5rem",
              fontWeight: "bold",
              marginBottom: "1rem",
              width: "100%",
            }}
          >
            <a
              style={{
                color: "#16c680",
              }}
              href={interest_bulletin}
              download
            >
              Interest Bulletin - Download
            </a>
          </p>
          <p
            style={{
              borderRadius: "15px",
              border: "4px #16c680 solid",
              padding: "2px 5px",
              fontSize: "1.5rem",
              fontWeight: "bold",
              width: "100%",
            }}
          >
            <a
              style={{
                color: "#16c680",
              }}
              href={tresor_general_terms_and_conditions}
              download
            >
              Tresor General Terms And Conditions - Download
            </a>
          </p>
        </div>
      ) : null}

      <div id="take">
        <div
          onClick={() => {
            const tkDc = document.getElementById("take");

            try {
              tkDc.children[0].classList.remove("take-hover");
              tkDc.children[0].classList.add("take-flex");

              set_potr_apply(true);
            } catch {}
          }}
          className="take-hover"
        >
          {potr_apply === true ? (
            <>
              <form>
                <input
                  type="email"
                  required={true}
                  maxLength={50}
                  placeholder="Enter your email..."
                />
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    const tkDc = document.getElementById("take");

                    tkDc.children[0].classList.remove("take-flex");

                    set_potr_apply("next");
                  }}
                  type="submit"
                  style={{ background: "green" }}
                >
                  <i className="fa-solid fa-check"></i>
                </button>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    const tkDc = document.getElementById("take");
                    tkDc.children[0].classList.add("take-hover");
                    tkDc.children[0].classList.remove("take-flex");

                    set_potr_apply(false);
                  }}
                  type="button"
                  style={{ background: "red", cursor: "pointer" }}
                >
                  <i className="fa-solid fa-xmark"></i>
                </button>
              </form>
            </>
          ) : potr_apply === "next" ? (
            <h2
              onClick={(e) => {
                e.stopPropagation();
              }}
              style={{ color: "white" }}
            >
              {lang === "EN"
                ? `Request sent!`
                : lang === "BG"
                ? `Заявлението изпратено!`
                : `Anfrage gesendet!`}
            </h2>
          ) : (
            <button>
              {lang === "EN"
                ? `Apply now!`
                : lang === "BG"
                ? `Заявете сега!`
                : `Jetzt Auftragen!`}
            </button>
          )}
        </div>
      </div>
    </>
  );
}

export default Tresorf;
