import "./home.css";
import Slideshow from "./slider";
import { useEffect } from "react";

function Home({ lang, navigate }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Slideshow />
      <div id="home">
        <div id="home-wrapper">
          <div className="cards">
            <div
              onClick={() => {
                navigate("/consumerCredit");
              }}
              className="card"
            >
              <i
                style={{ pointerEvents: "none" }}
                className="fa-solid fa-sack-dollar"
              ></i>
              <p>
                {lang === "EN"
                  ? "Consumer Credit"
                  : lang === "BG"
                  ? "Потребителски Кредит"
                  : "Verbraucherkredit"}
              </p>
              <i
                style={{ pointerEvents: "none" }}
                className="icon fa-solid fa-arrow-right-long"
              ></i>
            </div>
            <div
              onClick={() => {
                navigate("/creditcard");
              }}
              className="card"
            >
              <i
                style={{ pointerEvents: "none" }}
                className="fa-solid fa-credit-card"
              ></i>
              <p>
                {lang === "EN"
                  ? "Credit Card"
                  : lang === "BG"
                  ? "Кредитна Карта"
                  : "Kreditkarte"}
              </p>
              <i
                style={{ pointerEvents: "none" }}
                className="icon fa-solid fa-arrow-right-long"
              ></i>
            </div>
            <div
              onClick={() => {
                navigate("/debitcard");
              }}
              className="card"
            >
              <i
                style={{ pointerEvents: "none" }}
                className="fa-regular fa-credit-card"
              ></i>
              <p>
                {lang === "EN"
                  ? "Debit Card"
                  : lang === "BG"
                  ? "Дебитна Карта"
                  : "Debitkarte"}
              </p>
              <i
                style={{ pointerEvents: "none" }}
                className="icon fa-solid fa-arrow-right-long"
              ></i>
            </div>
            <div
              onClick={() => {
                navigate("/tresor");
              }}
              className="card"
            >
              <i
                style={{ pointerEvents: "none" }}
                className="fa-solid fa-vault"
              ></i>
              <p>
                {lang === "EN"
                  ? "Vault - Individuals"
                  : lang === "BG"
                  ? "Трезор - Физически Лица"
                  : "Tresor - Individuen"}
              </p>
              <i
                style={{ pointerEvents: "none" }}
                className="icon fa-solid fa-arrow-right-long"
              ></i>
            </div>
            <div
              onClick={() => {
                navigate("/deposit");
              }}
              className="card"
            >
              <i
                style={{ pointerEvents: "none" }}
                className="fa-sharp fa-solid fa-piggy-bank"
              ></i>
              <p>
                {lang === "EN"
                  ? "Deposit Account"
                  : lang === "BG"
                  ? "Депозитна Сметка"
                  : "Einlagenkonten"}
              </p>
              <i
                style={{ pointerEvents: "none" }}
                className="icon fa-solid fa-arrow-right-long"
              ></i>
            </div>
            <div className="card">
              <i
                style={{ pointerEvents: "none" }}
                className="fa-solid fa-dollar"
              ></i>
              <p
                onClick={() => {
                  navigate("/nabsm");
                }}
              >
                {lang === "EN"
                  ? "Collection Account"
                  : lang === "BG"
                  ? "Набирателна Сметка"
                  : "Vermögensänderungskonten"}
              </p>
              <i
                style={{ pointerEvents: "none" }}
                className="icon fa-solid fa-arrow-right-long"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
