import "./nabsm.css";
import interest_bulletin from "../../assets/docx/interest_bulletin.docx";
import Collection_Account_general_terms_and_conditions from "../../assets/docx/CollectionAcc.doc";
import React from "react";
import { useState, useEffect } from "react";

function Nabsm({ lang }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [potr_apply, set_potr_apply] = useState(false);

  const [active, setActive] = useState({
    status: false,
    element: null,
    index: null,
  });
  let c;

  function caret(e) {
    if (!active.status) {
      c = {
        status: true,
        element: document.getElementById("main-caret"),
        index: 1,
      };
    } else {
      c = active;
    }
    const main = document.getElementById("main-caret");
    if (
      e.target.id === "main-caret" &&
      !e.target.children[2].classList.contains("none")
    ) {
      setActive({
        status: true,
        element: e.target,
        index: e.target.dataset.index,
      });
    } else {
      c.element.children[2].classList.add("none");
      e.target.children[2].classList.remove("none");
      setActive({
        status: true,
        element: e.target,
        index: e.target.dataset.index,
      });
    }
  }

  return (
    <>
      <div id="deposit-banner"></div>
      <div className="mini-menu">
        <ul className="mini-links">
          <li data-index="1" id="main-caret" onClick={caret}>
            <i className="fa-solid fa-circle-info mini-icons"></i>
            <span className="mini-link-title">
              {lang === "EN"
                ? "Information"
                : lang === "BG"
                ? "Информация"
                : "Information"}
            </span>
            <i data-b="1" className="fa-solid fa-caret-up display"></i>
          </li>
          <li data-index="2" onClick={caret}>
            <i className="fa-solid fa-sliders mini-icons"></i>
            <span className="mini-link-title">
              {lang === "EN"
                ? "Parameters"
                : lang === "BG"
                ? "Параметри"
                : "Parameter"}
            </span>
            <i data-b="0" className="fa-solid fa-caret-up none display"></i>
          </li>

          <li data-index="4" onClick={caret}>
            <i className="fa-solid fa-file mini-icons"></i>
            <span className="mini-link-title">
              {lang === "EN"
                ? "Documents"
                : lang === "BG"
                ? "Документи"
                : "Dokumente"}
            </span>
            <i data-b="3" className="fa-solid fa-caret-up none display"></i>
          </li>
        </ul>
      </div>
      {Number(active.index) === 1 || active.index === null ? (
        <div className="potr-section">
          <h1 style={{ textAlign: "center" }}>
            {lang === "EN"
              ? "Collection Account"
              : lang === "BG"
              ? "Набирателна Сметка"
              : "Vermögensänderungskonten"}
          </h1>
          <div>
            <div id="potr-info">
              {lang === "EN" ? (
                <p
                  style={{
                    fontSize: "2rem",
                  }}
                >
                  The fundraising account is a bank account for legal entities
                  only, which is used to raise the capital of an enterprise that
                  is being established and registered in the Commercial
                  Register, as it has not yet started to carry out its main
                  activity. In such a case, his property coincides with the
                  assets. After the establishment itself, this bank account
                  becomes a payment account for legal entities, so that it can
                  carry out its financial business operations, including
                  payments, without problems and legally control its activity.
                </p>
              ) : lang === "BG" ? (
                <p
                  style={{
                    fontSize: "2rem",
                  }}
                >
                  Набирателната сметка е банкова сметка само за юридически лица,
                  която се използва за набиране на основен капитал на
                  предприятие, което се учредява и се записва в Търговския
                  регистър, като то все още не е започнало да извършва своята
                  основна дейност. В такъв случай имуществото му съвпада с
                  активите. След самото учредяване тази банкова сметка се
                  превръща в разплащателна сметка за юридически лица, за да може
                  то да извършва финансовите си стопански операции, включително
                  и разплащания, безпроблемно и да контролира правно своята
                  дейност.
                </p>
              ) : (
                <p
                  style={{
                    fontSize: "2rem",
                  }}
                >
                  Das Fundraising-Konto ist ein Bankkonto nur für juristische
                  Personen, das zur Beschaffung des Kapitals eines Unternehmens
                  dient, das gegründet und in das Handelsregister eingetragen
                  wird, da es seine Haupttätigkeit noch nicht aufgenommen hat .
                  In einem solchen Fall fällt sein Eigentum mit dem Vermögen
                  zusammen. Nach der Einrichtung selbst wird dieses Bankkonto zu
                  einem Zahlungskonto für juristische Personen, damit sie ihre
                  Finanzgeschäfte, einschließlich Zahlungen, problemlos
                  durchführen und ihre Tätigkeit rechtlich kontrollieren können.
                </p>
              )}
            </div>
          </div>
        </div>
      ) : null}
      {Number(active.index) === 2 ? (
        <div className="potr-section">
          <h1 style={{ textAlign: "center" }}>
            {lang === "EN"
              ? "Parameters"
              : lang === "BG"
              ? "Параметри"
              : "Parameter"}
          </h1>
          <div id="par-potr">
            <div>
              <i className="fa-sharp fa-solid fa-dollar-sign"></i>
              <div>
                <h1>
                  {lang === "EN"
                    ? "Currency"
                    : lang === "BG"
                    ? "Валута"
                    : "Währung"}
                </h1>
                <p>
                  {lang === "EN"
                    ? "Leva / Euro"
                    : lang === "BG"
                    ? "Лева / Евро"
                    : "Leva / Euro"}
                </p>
              </div>
            </div>
            <div>
              <i className="fa-solid fa-sack-dollar"></i>
              <div>
                <h1>
                  {lang === "EN"
                    ? "Account Closing Fee"
                    : lang === "BG"
                    ? "Такса за закриване на сметка"
                    : "Kontoauflösungsgebühr"}
                </h1>
                <p>
                  {lang === "EN"
                    ? "Free of charge"
                    : lang === "BG"
                    ? "Безплатно"
                    : "Kostenlos"}
                </p>
              </div>
            </div>

            <div>
              <i className="fa-solid fa-sack-dollar"></i>
              <div>
                <h1>
                  {lang === "EN"
                    ? "Funding account opening fee"
                    : lang === "BG"
                    ? "Такса за откриване на набирателна сметка"
                    : "Kontoeröffnungsgebühr finanzieren"}
                </h1>
                <p>
                  {lang === "EN"
                    ? "Free of charge"
                    : lang === "BG"
                    ? "Безплатно"
                    : "Kostenlos"}
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {Number(active.index) === 4 ? (
        <div className="potr-section">
          <p
            style={{
              borderRadius: "15px",
              border: "4px #16c680 solid",
              padding: "2px 5px",
              fontSize: "1.5rem",
              fontWeight: "bold",
              marginBottom: "1rem",
              width: "100%",
            }}
          >
            <a
              style={{
                color: "#16c680",
              }}
              href={interest_bulletin}
              download
            >
              Interest Bulletin - Download
            </a>
          </p>
          <p
            style={{
              borderRadius: "15px",
              border: "4px #16c680 solid",
              padding: "2px 5px",
              fontSize: "1.5rem",
              fontWeight: "bold",
              width: "100%",
            }}
          >
            <a
              style={{
                color: "#16c680",
              }}
              href={Collection_Account_general_terms_and_conditions}
              download
            >
              Collection Account General Terms And Conditions - Download
            </a>
          </p>
        </div>
      ) : null}

      <div id="take">
        <div
          onClick={() => {
            const tkDc = document.getElementById("take");

            try {
              tkDc.children[0].classList.remove("take-hover");
              tkDc.children[0].classList.add("take-flex");

              set_potr_apply(true);
            } catch {}
          }}
          className="take-hover"
        >
          {potr_apply === true ? (
            <>
              <form>
                <input
                  type="email"
                  required={true}
                  maxLength={50}
                  placeholder="Enter your email..."
                />
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    const tkDc = document.getElementById("take");

                    tkDc.children[0].classList.remove("take-flex");

                    set_potr_apply("next");
                  }}
                  type="submit"
                  style={{ background: "green" }}
                >
                  <i className="fa-solid fa-check"></i>
                </button>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    const tkDc = document.getElementById("take");
                    tkDc.children[0].classList.add("take-hover");
                    tkDc.children[0].classList.remove("take-flex");

                    set_potr_apply(false);
                  }}
                  type="button"
                  style={{ background: "red", cursor: "pointer" }}
                >
                  <i className="fa-solid fa-xmark"></i>
                </button>
              </form>
            </>
          ) : potr_apply === "next" ? (
            <h2
              onClick={(e) => {
                e.stopPropagation();
              }}
              style={{ color: "white" }}
            >
              {lang === "EN"
                ? `Request sent!`
                : lang === "BG"
                ? `Заявлението изпратено!`
                : `Anfrage gesendet!`}
            </h2>
          ) : (
            <button>
              {lang === "EN"
                ? `Apply now!`
                : lang === "BG"
                ? `Заявете сега!`
                : `Jetzt Auftragen!`}
            </button>
          )}
        </div>
      </div>
    </>
  );
}

export default Nabsm;
